import CrmDate from "../components/crm_date.vue";
import CrmSelect from "../components/crm_select.vue";
import CrmCheckbox from "../components/crm_checkbox.vue";

export function useFormControl(picklistValues, fieldRequiredText) {
  function formControl(fld) {
    return (
      {
        picklist: CrmSelect,
        checkbox: CrmCheckbox,
        date: CrmDate,
        birthday: CrmDate,
      }[fld.type] || "q-input"
    );
  }

  function formControlProps(fld, instance) {
    let fldProps = {
      picklist: {
        options: picklistValues[fld.picklist_id],
        emitValue: true,
        mapOptions: true,
      },
      textarea: {
        type: "textarea",
      },
      email: {
        type: "email",
      },
    };

    return {
      modelValue: instance[fld.name],
      "onUpdate:modelValue": (v) => (instance[fld.name] = v),
      label: fld.label,
      hideBottomSpace: true,
      filled: true,
      rules: fld.mandatory
        ? [(val) => !!val || val === 0 || fieldRequiredText]
        : undefined,
      ...(fldProps[fld.type] || {}),
    };
  }

  return { formControl, formControlProps };
}
